<template>

<div>
    <div class="match-wrapper " v-for="(o, index) in matchList" :key="index" v-show="!isLoading && !isShowNoData">
        <div class="display-flex-align-center home-match__title-wrapper" >
           <router-link  :to="{path:`/${currentLocale}/${currentSportType}/${o.countryKey}/${o.leagueKey}`,query: { leagueId: o.leagueId, subLeagueId: o.subLeagueId }}" class="display-flex-align-center home-match__title-wrapper" >
                <div class="home-match__title">{{o.country}}</div>
				<span class="home-match__title" v-if="o.leagueName !==null">| </span>
                <div class="home-match__title" v-if="o.leagueName !==null"> {{o.leagueName}}</div>
                <div class="home-match__title" v-if="!!o.subLeagueName">: {{o.subLeagueName}}</div>
                <div class="ml-s">
                    <svg width="6" height="10" viewBox="0 0 6 10"  class="home-match__title-icon" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.334961 8.46017L3.57913 5.20892L0.334961 1.95767L1.33371 0.958923L5.58371 5.20892L1.33371 9.45892L0.334961 8.46017Z" />
                    </svg>
                </div> 
            </router-link>
        </div>
                
        <div class="display-flexs highlights-match__wrapper">
            <swiper :slidesPerView="isMobile ?1.5:3.5" :navigation="isMobile ?false:o.matchList.length>3?true:false" :spaceBetween="18" :freeMode="true" class="mySwiper ">
                <swiper-slide class="home-match__container match-card__wrapper" v-for="(level2,index) in o.matchList" :key="index" :class="classes"  >  
                    <MatchCard :data=level2 :parentData=o :date="date" :classes="'mr-1rem'" ></MatchCard>                         
                </swiper-slide>                
            </swiper>                  
        </div>
    </div>
    <Loading v-show="isLoading"></Loading>
    <div class="no-data" v-show="isShowNoData && !isLoading">{{$t('NO_HIGHLIGHTS')}}</div>
 </div>

</template>

<script>
import MatchCard from '@/components/share/MatchCard.vue'
import {mapGetters,mapActions} from 'vuex'
import config from '@/js/config.js'

import { Swiper, SwiperSlide } from 'swiper/vue';
// import Swiper core and required modules
import SwiperCore, {
  Pagination,Navigation
} from 'swiper/core';
SwiperCore.use([Pagination,Navigation]);
export default {
    components:{
        Swiper,
        SwiperSlide,
        MatchCard
    },
    props: {
        matchObj: {
            type: Array
        },
        isShowFav : {
            type:Boolean
        },
    },
    
    watch:{
		params:{
			deep:true,
	        	handler(newVal,oldVal) {
             
                if (typeof newVal.leagueKey !== 'undefined') {
                    this.getFixtureByLeagueData(newVal);
                }if(typeof newVal.teamId !== 'undefined'){
                    //this.getMatchListByTeamData(newVal);
                }
                else {
                    this.getMatchListData(newVal);
                }
				
			}
		}
    },

    data() {
        return {
            s3ImgUrl:config.s3ImgUrl,
            isLoading: false,
            isShowNoData:false,
            path :[],

            params : {},
            matchList:[]        
        }
    },

	computed:{
           ...mapGetters([              
                'isMobile',
                'currentLocale',
                'currentSportType'
           ]),
            matchList(){ //filter for matchList if score ==="", then show "?"
                      
                let matchList = this.matchList;
                for (let level1 = 0; level1<matchList.length; level1++) {
                    for (let level2 = 0; level2<matchList[level1].matchList.length; level2++){
                        for (let level3 = 0; level3<matchList[level1].matchList[level2].opponents.length; level3++){
                            if (matchList[level1].matchList[level2].opponents[level3].score ===""){
                                matchList[level1].matchList[level2].opponents[level3].score = "-"
                            }
                        }
                    }
                }
                return matchList
            }
	},
    created() {      
         this.path=this.$route.path;         
    },
    mounted() {
        
    },
    created(){
    },
    methods:{
        ...mapActions([
            "getMatchListAsync",
            "getFixtureByLeague",
            "getMatchListByTeam",
            "getHighlightList"
        ]),
       async getMatchListData(newVal){
       
            let params={
				leagueIdList:newVal.leagueIdList.toString(),
				matchDate:newVal.date,
				timeZone:this.$tools.getCurrentTimeZone()
			};
        
            this.date = newVal.date;
         
            this.isLoading = true;
            const result = await this.getHighlightList(params);
            this.isLoading = false;
            
            if ( result.result === null) {
                this.isShowNoData = true;
            } else {
                if (result.result.leagueMatchList.length === 0) {                       
                    this.isShowNoData = true;
                } else {
                    this.isShowNoData = false
                }
            }
          
           this.matchList =  result.result.leagueMatchList;
           this.$emit('changeMatchContentEvent',this.matchList);
    	},
        
        async getMatchListByTeamData(newVal){
            let params={
				teamId:newVal,
				timeZone:this.$tools.getCurrentTimeZone()
			};
     
            this.isLoading = true;
            const result = await this.getMatchListByTeam(params);
            this.isLoading = false;
  
            if (result.result.leagueMatchList.length === 0) {                
                this.isShowNoData = true;
            } else {
                this.isShowNoData = false
            }

           this.matchList =  result.result.leagueMatchList;
           this.$emit('changeMatchContentEvent',this.matchList);
    	},
       
	    async getFixtureByLeagueData(newVal){
			let params = {
				leagueKey:newVal.leagueKey,
				matchDate:newVal.matchDate,
				timeZone:this.$tools.getCurrentTimeZone()
			}
		    this.isLoading = true;
			const result = await this.getFixtureByLeague(params);
            this.isLoading = false;
			this.matchList = result.result.matchList;
           
            if (this.matchList.length == 0) {                
                this.isShowNoData = true;
            } else {
                this.isShowNoData = false
            }
			this.$emit('changeMatchContentFixtureByLeagueEvent',this.matchList);
		},

        selectFavMatch(o){
            o.fav =!o.fav
        },


     }
}
</script>

<style scoped>
    /* .test{
        width: 3rem;
        height:3rem;
        background-color: red;
    } */
    .swiper-button-prev:after, .swiper-button-next:after{
        font-size: 1rem;
        color: white;
    }
    .highlights-match__wrapper{
        /* overflow-x: scroll; */
    }
    .match-content__wrapper{
        width: 60% !important;
            min-height: 15rem;
    }
    .home-match__win-lose{
        background-color: green;
        border-radius: 50%;
        padding: .3rem;
        color: white;
        width: .75rem;
        border: .1rem solid white;
        height: .75rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: .5rem;
    }
    .home-match__win-lose.W{
        background-color: #5CD66C;    
    }
    .home-match__win-lose.L{
        background-color: #CF283E;    
    }
      .home-match__win-lose.D{
        background-color:   #ED9C5A;    
    }
    .match-wrapper{
        margin-bottom: 1.2rem;
    }
    .match-container{
        display: flex;
        background-color: #212B30;
        color: #CFD9DE;
        margin-bottom: .5rem;
        padding: .6rem .63rem;
        border-radius: .5rem;
        font-size: .88rem;
        text-align: left;
        min-width: 36rem;
    }
	.match-status__live{
		background-color: #CF283E;
		padding: .2rem;
    	border-radius: .3rem;
		font-size:.65rem
	}
    .match-detail{
        display:flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
    .match-detail__score{
        width: 1rem;
        text-align: center;
    }
    .match-detail__name{
        width: 8rem;
        overflow: hidden;
        text-align: right;
    }
    .match-middle{
        flex: 0.5;
        display: flex;
        justify-content: center;
        align-items: center;
        color:white;
    }
    .match-middle__seperator{
        margin: 0 .5rem;
        font-weight: bold;
        font-weight: bold;
    }
</style>